import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmog, fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBehance, faDribbble, faGithub, faFlickr, faSquarespace, faBitbucket, faThemeco } from '@fortawesome/free-brands-svg-icons'
import './Profile.css';
import OuterbaseImg from "../../assets/images/hackathon/outerbase.webp"
import CodeAiImg from "../../assets/images/hackathon/code-ai.webp"
import MondayImg from "../../assets/images/hackathon/monday-com-climate.webp"
import GoogleImage from "../../assets/images/hackathon/Google.webp"
import AWSImage from "../../assets/images/hackathon/AWS.webp"


library.add(fas, faBehance, faGithub, faDribbble, faFlickr, faSmog, faSquarespace, faBitbucket, faThemeco)

export default function Profile() {
	return (
		<section id="profiles" className="profiles">
			<div className="profiles-details">
				<div className="section-heading text-center">
					<h2 style={{ color: '#3c096c' }}>Hackathons</h2>
				</div>
				<div className="container">
					<div className="profiles-content">
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={OuterbaseImg} width={30} height={30} alt="Outerbase logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>Outerbase + Hashnode</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px'}}><a href="https://curiousmind.hashnode.dev/database-dashboard-for-headless-cms" targer="_blank" rel="noreferrer">Database dashboard for headless CMS</a></div>
										<p className="profile-icon-desc">Solving a real world problem in headless CMS world!</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={CodeAiImg} width={30} height={30} alt="codeai logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>Code.ai</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" ><a href="https://devpost.com/software/problem-solving-template" rel="noreferrer" target="_blank">Coda's AI at Work Challenge</a></div>
										<p className="profile-icon-desc"> Setup a problem-solving template framework in Coda.io</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={MondayImg} width={30} height={30} alt="monday logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>Monday.com climate</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px' }}><a href="https://devpost.com/software/greener-habits" target="_blank" rel="noreferrer">Green Habits</a></div>
										<p className="profile-icon-desc">Contributed in development and deployed of backend API in python. Instrumental in sharing idea for badge journey in the app with UX team.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile profile-no-border">
								<div className="profile-txt">
									<FontAwesomeIcon icon={faGithub} size="xl" />
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>World Innovation Day</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px' }}><a href="https://github.com/pritisolanki/HackathonApp#worldhackathon-day-apr-2021" target="_blank" rel="noreferrer">World Innovation Day</a></div>
										<p className="profile-icon-desc">Successful submission of Minderoo's challenge to build resilient communities after natural disaster. I outline the solution, gather the team of eight creative minds. Developed python script to generate synthetic data for ML model for engineers for recommender system.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="profiles-content profile-topborder" >
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={AWSImage} width={30} height={30} alt="AWS PartyRock logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>PolyAI</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px'}}><a href="https://devpost.com/software/polyai" targer="_blank" rel="noreferrer">The PartyRock Generative AI Hackathon by AWS</a></div>
										<p className="profile-icon-desc">Your Creative Assistant for Boundless Inspiration</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={GoogleImage} width={30} height={30} alt="Google AI Hackathon logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>Geminus</div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px'}}><a href="https://devpost.com/software/geminus" targer="_blank" rel="noreferrer">Google AI Hackathon</a></div>
										<p className="profile-icon-desc">Driving empowered by Gemini AI</p>
									</div>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>
			<br />
		</section>
	);
}
