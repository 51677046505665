import React from "react";
import "./Education.css"

export default function Education() {
    return (

        <section id="education" className="education">
            <div className="section-heading text-center">
                <h2 style={{ color: '#3c096c' }}>education</h2>
            </div>
            <div className="container">
                <div className="education-horizontal-timeline">
                    <div className="single-items">   
                    <div className="single-item">
                            <div className="single-horizontal-timeline">
                                <div className="experience-time">
                                    <h2>2024</h2>
                                    <h3>Certificate Courses</h3>
                                </div>
                                <div className="timeline-horizontal-border">
                                    <i className="fa fa-circle" aria-hidden="true"></i>
                                    <span className="single-timeline-horizontal"></span>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-content">
                                        <h4 className="title">
                                            ELVTR
                                        </h4>
                                        <h5>Remote, US</h5>
                                        <p className="description">
                                            Completed - < a href="https://www.linkedin.com/posts/pritisolanki_elvtr-elevateyourcareer-lifelonglearning-activity-7240019588942331906-QylR" target="_blank" className="education-href" rel="noreferrer">AI Solution Architect</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                         
                        <div className="single-item">
                            <div className="single-horizontal-timeline">
                                <div className="experience-time">
                                    <h2>2023</h2>
                                    <h3>Certificate Courses</h3>
                                </div>
                                <div className="timeline-horizontal-border">
                                    <i className="fa fa-circle" aria-hidden="true"></i>
                                    <span className="single-timeline-horizontal"></span>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-content">
                                        <h4 className="title">
                                            MIT Sloan Executive Education, MIT xPro
                                        </h4>
                                        <h5>Remote, US</h5>
                                        <p className="description">
                                            Completed <a href="https://mit-online.getsmarter.com/presentations/lp/mit-organizational-design-for-digital-transformation-online-short-course/" target="_blank" className="education-href" rel="noreferrer">Digital Business Strategy: Harnessing Our Digital Future</a>
                                            <br/>
                                            Completed <a href="https://mit-xpro-online-education.emeritus.org/designing-building-ai-products-services" target="_blank" className="education-href" rel="noreferrer">Designing and Building AI Products and Services</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-item">
                            <div className="single-horizontal-timeline">
                                <div className="experience-time">
                                    <h2>2021</h2>
                                    <h3>Graduate Program In Cloud Computing</h3>
                                </div>
                                <div className="timeline-horizontal-border">
                                    <i className="fa fa-circle" aria-hidden="true"></i>
                                    <span className="single-timeline-horizontal"></span>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-content">
                                        <h4 className="title">
                                            UNIVERSITY OF TEXAS
                                        </h4>
                                        <h5>AUSTIN, USA</h5>
                                        <p className="description">
                                            Finished online graduate program certification course in<a href="https://onlineexeced.mccombs.utexas.edu/online-cloud-computing-course" target="_blank" className="education-href" rel="noreferrer"> cloud computing.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-item">
                            <div className="single-horizontal-timeline">
                                <div className="experience-time">
                                    <h2>2005</h2>
                                    <h3>Master <span>in </span> Computer Application</h3>
                                </div>
                                <div className="timeline-horizontal-border">
                                    <i className="fa fa-circle" aria-hidden="true"></i>
                                    <span className="single-timeline-horizontal"></span>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-content">
                                        <h4 className="title">
                                            Gujarat University
                                        </h4>
                                        <h5>India</h5>
                                        <p className="description">
                                            Completed MCA from <a href="https://www.skpimcs.org/" target="_blank" className="education-href" rel="noreferrer">S.K.Patel Institute of Management & Computer Studies</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single-items"> 
                    <div className="single-item">
                            <div className="single-horizontal-timeline">
                                <div className="experience-time">
                                    <h2>2002</h2>
                                    <h3>Bachelors <span>in </span> Computer Application</h3>
                                </div>
                                <div className="timeline-horizontal-border">
                                    <i className="fa fa-circle" aria-hidden="true"></i>
                                    <span className="single-timeline-horizontal"></span>
                                </div>
                                <div className="timeline">
                                    <div className="timeline-content">
                                        <h4 className="title">
                                            Gujarat University
                                        </h4>
                                        <h5>India</h5>
                                        <p className="description">
                                            Completed BCA with distinction from <a href="https://www.navgujaratbca.com/" target="_blank" rel="noreferrer" className="education-href">Nav-Gujarat College of Computer Application</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="single-items">
                    <div className="single-item">
                        <div className="title">
                            Certification
                        </div><br />
                        <ul style={{ margin: '10px' }}>
                            <li className="list">
                                <span className="experience-hl">AWS Solution Architect Associate</span>, Sept 2021, Amazon Web Services
                            </li>
                            <li className="list">
                                <span className="experience-hl">Microsoft Certified: Azure Fundamentals</span>, Apr 2021, Microsoft
                            </li>
                            <li className="list">
                                AI programming with Python Nanodegree, Sept 2018, Udacity, USA
                            </li>
                            <li className="list">
                                PHP 5.3 Zend certified Engineer, Sept 2011, Zend
                            </li>
                            <li className="list">
                                Cybage Certified Trainer, Sept 2010, India
                            </li>
                        </ul>
                    </div>
                    <div className="single-item">
                        <div className="title">
                            Trainings
                        </div><br />
                        <ul style={{ margin: '10px' }}>
                            <li className="list">
                                Self Leadership, KenBlanchard @Clearmotion
                            </li>
                            <li className="list">
                                Dreamforce @RRI
                            </li>
                            <li className="list">
                                Unleash the Power within @RRI
                            </li>
                            <li className="list">
                                Cybage certified trainer Training program @Cybage
                            </li>

                        </ul>
                    </div>
                    <div className="single-item">
                        <div className="title">
                            Recognition
                        </div><br />
                        <ul style={{ margin: '10px' }}>
                            <li className="list">
                                Platinum Team Player @RRI
                            </li>
                            <li className="list">
                                Outstanding Trainer @Cybage
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}