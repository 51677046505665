import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingMedical, faLeaf, faSmog, fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBehance, faDribbble, faGithub, faFlickr, faSquarespace, faBitbucket, faThemeco, } from '@fortawesome/free-brands-svg-icons'
import './Social.css';

library.add(fas, faBehance, faGithub, faDribbble, faFlickr, faSmog, faSquarespace, faBitbucket, faThemeco, faLeaf)

export default function Social() {
    return (
        <section id="social" className="social">
            <div className="profiles-details">
                <div className="section-heading text-center">
                    <h2 style={{ color: '#3c096c' }}>Social Contribution</h2>
                </div>
                <div className="container">
                    <div className="social-content">
                        <div className="profile-wrapper">
                            <div className="single-social">
                                <div className="social-txt">
                                    <div><FontAwesomeIcon icon={faHandHoldingMedical} style={{ color: "#d63384", }} /></div>
                                    <div className="social-icon-name">Donated $$ <br/> for eradicating hunger</div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-wrapper">
                            <div className="single-social">
                                <div className="social-txt">
                                    <div><FontAwesomeIcon icon={faLeaf} style={{ color: "green", }} /></div>
                                    <div className="social-icon-name"> Donated 50 trees <br/>to National Forest of USA</div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-wrapper">
                            <div className="single-social">
                                <div className="social-txt">
                                    <div><FontAwesomeIcon icon={faHandHoldingMedical} style={{ color: "#d63384", }} /></div>
                                    <div className="social-icon-name">Donation $$ <br />for Seva Kendra</div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-wrapper">
                            <div className="single-social social-no-border">
                                <div className="social-txt">
                                    <div><FontAwesomeIcon icon={faHandHoldingMedical} style={{ color: "#d63384", }} /></div>
                                    <div className="social-icon-name">Donate food <br />at food pantry</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
