import React from "react";
import './Demo.css';
import Image1 from '../../assets/images/portfolio/Image1.webp';
import Image2 from '../../assets/images/portfolio/Image2.webp';
import center from '../../assets/images/portfolio/center.webp';
import Image3 from '../../assets/images/portfolio/Image3.webp';
import Image4 from '../../assets/images/portfolio/Image4.webp';


export default function Portfolio() {
    return (
        <section id="demo" className="portfolio">
            <div className="portfolio-details">
                <div className="section-heading text-center">
                    <h2 style={{ color: '#3c096c' }}>Demos</h2>
                </div>
                <div className="container">
                    <div className="portfolio-content">
                        <div className="isotope">
                            <div className="row portfolio-photo">
                                <div className="col-sm-4 content-wrapper">
                                    <div className="item demo-item-wrapper">
                                        <img src={Image1} alt="demo of hometown sample website"/>
                                        <div className="isotope-overlay">
                                            <div>
                                                <span className="demo-text">HTML, CSS, Vercel</span>
                                                <br/>
                                                <a href="https://astounding-crepe-a431a3.netlify.app/" target="_blank" className="demo-overlay-link" rel="noreferrer">Demo</a><span className="demo-overlay-link"> | </span><a href="https://github.com/pritisolanki/hometown" target="_blank" className="demo-overlay-link" rel="noreferrer">Github</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item demo-item-wrapper">
                                        <img src={Image2} style={{border:'1px solid #9178d6'}} alt="demo of lwoering ordering app"/>
                                        <div className="isotope-overlay">
                                            <div>
                                            <span className="demo-text">HTML, CSS, Javascript, Vercel</span>
                                            <br/>
                                            <a href="https://jazzy-churros-0b640e.netlify.app/" target="_blank" className="demo-overlay-link" rel="noreferrer">Demo</a><span className="demo-overlay-link" > | </span><a href="https://github.com/pritisolanki/flowerOrderingApp" target="_blank" className="demo-overlay-link" rel="noreferrer">Github</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4 content-wrapper">
                                    <div className="item demo-item-wrapper">
                                        <img src={center} style={{border:'1px solid #fc6847'}} alt="demo of react app - quizzlet"/>
                                        <div className="isotope-overlay">
                                            <div>
                                                <span className="demo-text">React, HTML, CSS, Responsive, Netlify</span>
                                                <br/>
                                                <a href="https://cool-squirrel-02b169.netlify.app/" target="_blank" className="demo-overlay-link" rel="noreferrer">Demo</a><span className="demo-overlay-link"> | </span><a href="https://github.com/pritisolanki/quizzlet" target="_blank" className="demo-overlay-link" rel="noreferrer">Github</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4 content-wrapper">
                                    <div className="item demo-item-wrapper">
                                    <img src={Image3} style={{border:'1px solid #6619ff'}} alt="demo of product splash page for ai art work"/>
                                        <div className="isotope-overlay">
                                            <div>
                                                <span className="demo-text">HTML, CSS, Responsive, AI Art, Vercel</span>
                                                <br/>
                                                <a href="https://remarkable-phoenix-80fc71.netlify.app/" target="_blank" className="demo-overlay-link" rel="noreferrer">Demo</a><span className="demo-overlay-link"> | </span><a href="https://github.com/pritisolanki/aiartwork" target="_blank" className="demo-overlay-link" rel="noreferrer">Github</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item sub-img demo-item-wrapper">
                                    <img src={Image4} style={{border:'1px solid #969696'}} alt="demo for Next.js nasa app"/>
                                        <div className="isotope-overlay">
                                            <div>
                                                <span className="demo-text">Next.js App, Nasa APi, Vercel</span>
                                                <br/>
                                                <a href="https://nasa-daily-photo-eta.vercel.app/" target="_blank" className="demo-text-href demo-overlay-link" rel="noreferrer">Demo</a><span > | </span><a href="https://github.com/pritisolanki/NASA-DailyPhoto" target="_blank"  className="demo-overlay-link" rel="noreferrer">Github</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}