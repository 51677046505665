import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "./Banner.css";

export default function Banner() {
    return (
        <section id="welcome-hero" className="welcome-hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center w-full">
                        <Carousel indicators={false} controls={false} >
                            <Carousel.Item interval={2000}>
                                <div className="header-text">
                                    <h2>I am a <br/><span>Technologists</span></h2>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <div className="header-text">
                                    <h2>Who believe in <br /><span> Workflow automation , Seamless integration , AI tools usage</span></h2>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <div className="header-text">
                                    <h2>Thrive on <br /> <span>Solving problem</span></h2>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <div className="header-text">
                                    <h2>Love <br /> <span>Exploring solutions</span></h2>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <div className="header-text">
                                    <h2>Like <br /> <span>to invest time in learning and upgrading</span></h2>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
}

