import React from "react";
import { Link } from "react-router-dom";
import './Update.css';

export default function Update() {
    return (
        <div className="update-section">
            <div className="section-heading text-center">
                <h2 style={{ color: '#3c096c' }}>Recent Updates</h2>
            </div>
            <div className="update-content container">
                <ul className="update-box">
                    <li className="list">Completed Certification - AI Solution Architect, Sep 2024</li>
                    <li className="list">LangChain-based chatbot deployed on Hugging Face Spaces. Access information from PDFs, blogs, and databases through conversation. Jun 2024</li>
                    <li className="list">ChatGPT based article generator. Jun 2024</li>
                    <li className="list">Hackathon Entry:  <Link to="https://devpost.com/software/geminus" className="link" target="_blank">Geminus - driving empowered by Gemini AI</Link> configured on Vertex platform, deployed on Google Cloud </li>
                    <li className="list">Develop app on AWS PartyRock, <Link to="https://devpost.com/software/polyai" className="link" target="_blank">PolyAI - Your Creative Assistant for Boundless Inspiration</Link></li>
                    <li className="list">Deployed OpenAI custom AI bot on HuggingFace Spaces!, Feb 2024</li>
                </ul>
                <ul className="update-box">
                <li className="list"> Finished - <Link to="https://executiveeducation.wharton.upenn.edu/online-learning/self-paced-online-programs/business-analytics-certificate/" className="link" target="_blank">Business Analytics: From Data to Insights</Link>, Feb 2024</li>
                    <li className="list"> Setup a Voiceflow AI chatbot for course information lookup, Feb 2024</li>
                    <li className="list"> Integrate <i>"Spot"</i> demo AI bot using chatbase platform @pritisolanki.com, Feb 2024</li>
                    <li className="list"> Finished - <Link to="https://curiousmind.hashnode.dev/" className="link" target="_blank"> Digital Business Strategy: Harnessing Our Digital Future</Link> from MIT Sloan Executive Education, Sept 2023</li>
                    <li className="list"> Hackathon - <Link to="https://curiousmind.hashnode.dev/" className="link" target="_blank"> Database dashboard for headless CMS</Link>Database dashboard for headless CMS , Sept 2023</li>
                    <li className="list"> Finished - <Link to="https://mit-xpro-online-education.emeritus.org/" className="link" target="_blank"> Designing and Building AI Products and Services from MIT | xPro</Link> , Sept 2023</li>
                </ul>
            </div>
        </div>
    );
}