import React from "react";
import './Project.css';

export default function Project() {
    return (
        <section id="project" className="project">
            <div className="profiles-details">
                <div className="section-heading text-center">
                    <h2 style={{ color: '#3c096c' }}>AI Project</h2>
                </div>
                <div className="container">
                    <div className="social-content">
                        <div className="profile-wrapper">
                            <div className="single-project ">
                                <div>
                                    <b>Youtube Video Summarizer </b>
									<p>Python, Streamlit, Langchain, Huggingface Space</p>
									<p>The YouTube Summarizer is an NLP project providing concise summaries of YouTube video content. It uses advanced AI and NLP techniques to parse video transcripts to generate insightful content.</p>
                                </div>
                            </div>
                        </div>
                        <div className="profile-wrapper">
                            <div className="single-project ps-2 pe-2">
								<div>
                                    <b>ContextFul Chatbot</b>
									<p>Python, Streamlit, Langchain, Huggingface Space</p>
									<p>Custom developed chatbot which supports PDF, Blog and Database based interaction. User can perform conversation with uploed PDF, scraped content of blog or database uploaded file. </p>
                                </div>
                            </div>
                        </div>
                        <div className="profile-wrapper">
                            <div className="single-project ps-2 pe-2">
								<div>
                                    <b><a href="https://pritisolanki.com" target="_blank" className="education-href" rel="noreferrer">Spot</a></b>
									<p>Low Code Platform- Chatbase</p>
									<p>Spot is AI bot equipped to talk about current trends in content space. The bot persona is defined to be informational and friendly to explore the topic like how to future proof content , what is content goals etc.
									</p>
                                </div>
                            </div>
                        </div>
						<div className="profile-wrapper">
                            <div className="single-project ps-2 pe-2 no-border">
								<div>
                                    <b><a href="https://vimeo.com/909764991?share=copy" target="_blank" className="education-href" rel="noreferrer">VoiceFlow - Course Finder AI Bot</a></b>
									<p>Node js, Mock APIs, Postman, Voiceflow</p>
									<p>Course Finder AI bot is an intelligent virtual assistant designed to engage with users and assist them in discovering relevant courses. Using NLP and machine learning, the bot engages in conversation with customers, understanding and responding to queries about the courses offered.</p>
                                </div>
                            </div>
                        </div>						
                    </div>
                </div>
            </div>
        </section>
    );
}
