import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import './Footer.css';

export default function Footer() {
    return (
        <footer id="footer-copyright" className="footer-copyright">
            <div className="container">
                <div className="hm-footer-copyright text-center">
                </div>
            </div>
            <div id="scroll-Top">
                <div className="return-to-top">
                    <a href="#welcome-hero" style={{ padding: '10px' }}>
                        <FontAwesomeIcon icon={faAngleUp} />
                    </a>
                </div>
            </div>
        </footer>
    );
}