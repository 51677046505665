import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, fas } from '@fortawesome/free-solid-svg-icons'
import "./About.css"

library.add(fas, faTwitter, faLinkedinIn, faEnvelope)

export default function About() {
	return (
		<section id="about" className="about">
			<div className="section-heading text-center">
				<h2 style={{ color: '#3c096c' }}>about me</h2>
			</div>
			<div className="container">
				<div className="about-content">
					<div className="col-sm-6">
						<div className="single-about-txt">
							<h3>
								<b>AI Solution Architect | Specializing in AI Integration, Automation, and Digital Transformation</b>
							</h3>
							<div>
								<ul class="list-group">
									<li class="list-group-item"><b>Building AI chatbots:</b> Developing and deploying AI-powered chatbots for customer interactions, content generation, or other specific use cases.</li>
									<li class="list-group-item"><b>Architecting microservices-based systems:</b> Designing and implementing scalable, modular systems that enhance performance and allow for easier updates and maintenance.</li>
									<li class="list-group-item"><b>Implementing process automation:</b> Streamlining business processes through automation, reducing manual effort and increasing efficiency.</li>
									<li class="list-group-item"><b>Optimizing workflows:</b> Analyzing and improving operational workflows for better performance and reduced downtime.</li>
									<li class="list-group-item"><b>Integrating AI-driven tools:</b> Incorporating AI solutions into existing business systems to enhance decision-making, customer engagement, or product offerings.</li>
									<li class="list-group-item"><b>Performance Monitoring and Optimization:</b> Setting up monitoring tools and performance dashboards to identify and resolve system bottlenecks, ensuring smooth and efficient operations.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-sm-offset-1 col-sm-5 profile_image">
						<div className="single-about-img">
							<div className="about-list-icon">
								<ul>
									<li>
										<a href="https://twitter.com/pritisolanki" target="_blank" rel="noreferrer" aria-label="twitter">
											<FontAwesomeIcon icon={faTwitter} />
										</a>

									</li>
									<li>
										<a href="https://www.linkedin.com/in/pritisolanki/" target="_blank" rel="noreferrer" aria-label="linkedin">
											<FontAwesomeIcon icon={faLinkedinIn} />
										</a>
									</li>
									<li>
										<a href="mailto:pritijobs01@gmail.com?subject=From pritisolanki website" target="_blank" rel="noreferrer" aria-label="email">
											<FontAwesomeIcon icon={faEnvelope} />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}