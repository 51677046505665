import React from "react";
import SlidesImg from "../../assets/images/slides/Brainstorming.webp"



export default function Slides() {
	return (
		<section id="slides" className="profiles">
			<div className="profiles-details">
				<div className="section-heading text-center">
					<h2 style={{ color: '#3c096c' }}>Presentation</h2>
				</div>
				<div className="container">
					<div className="profiles-content">
						<div className="col-sm-3 profile-wrapper">
							<div className="single-profile">
								<div className="profile-txt">
									<img src={SlidesImg} width={120} height={120} alt="Outerbase logo"/>
									<div className="profile-icon-name" style={{ fontSize: '20px' }}>
                                    </div>
								</div>
								<div className="single-profile-overlay">
									<div className="profile-txt">
										<div className="profile-icon-subname" style={{ fontSize: '20px'}}><a href={`${process.env.PUBLIC_URL}/docs/brainstorming.pdf`} rel="noopener noreferrer" target="_blank"><u>Brainstorming - Web Centric to Content First</u></a></div>
										<p className="profile-icon-desc">In headless CMS, content first is the mindset. Let's brainstorm, why content first!<br/>This is education purpose presentation ONLY.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br />
		</section>
	);
}
